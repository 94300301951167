import { Link } from 'react-router-dom';
import * as React from 'react';
import ContactFormImg from '../assets/images/Illustration on Right Column.webp'
import { motion } from 'framer-motion';
import EmailForm from "./EmailForm";

  function ContactUs() {

    return (
    <>
        <motion.div>
          <div className='relative bg-[#161616] w-full text-white grid md:flex max-sm:py-40 max-sm:px-10' >
            
            <div className='absolute top-10 right-0 w-[100px]'>
              <Link to='/'>
                <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clip-rule="evenodd" d="M17.652 17.4894L0.703546 34.2684L0 33.5577L16.9413 16.7858L0.703768 0.710651L1.40731 0L17.652 16.0823L33.8967 0L34.6003 0.710651L18.3627 16.7858L35.304 33.5577L34.6005 34.2684L17.652 17.4894Z" fill="white"/>
                </svg>
              </Link>
            </div>
            
            <div className='lg:p-40 max-sm:order-last text-display w-2/3 md:w-1/2'>
              <div className=''>
                <h3 className='max-sm:hidden'>Contact Us</h3>
                <h4 className='pt-12 pb-4 text-display'>Address</h4>
                <address className='not-italic'>Unit 1 Guest House Farm, Runshaw Lane,<br></br>
                  Euxton ,Chorley, Lancashire, PR7 6HD<br></br>
                  United Kingdom</address>
                <h4 className='pt-12 pb-4'>Telephone</h4>
                <address className='not-italic flex flex-col w-full'>
                  <a href='tel:+44 (0) 1772 970742'><strong className='opacity-80'>UK</strong> +44 (0) 1772 970742</a>
                  <a href='tel:+1 786 905 1692'><strong className='opacity-80'>USA</strong> +1 786 905 1692</a>
                  <a href='tel:+1 437 524 9781'><strong className='opacity-80'>CA</strong> +1 437 524 9781</a>
                </address>

                <h4 className='pt-12 pb-4'>Email</h4>
                <address className='not-italic'>
                  <a href='mailto:enquiries@hydroswing.com'>enquiries@hydroswing.com</a>
                </address>
              </div>
            </div>

            <div className='font-display md:flex max-md:order-first block md:w-1/2' id='form'>
              <h3 className='md:hidden'>Contact Us</h3>
              <div className=' h-full w-full mx-auto my-auto text-black flex items-center '>
                <EmailForm />
              </div>
            </div>

            <div className="absolute bottom-0 right-0">
              <img className='max-sm:w-1/2 max-sm:float-right' src={ContactFormImg} alt='window outline illustration'/>
            </div>

          </div>


          <div className='w-screen border-t-2 border-white'>
            <Link to='/projects' className='inline-flex py-10 px-4 md:p-20 h-full w-full transition-color duration-500 bg-black hover:bg-[#597DAF]'>
              <h2 className='text-white inline my-auto md:pr-2 max-sm:text-[32px] md:pb-4'>See Our Works</h2>
                <div className=''>
                  <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[132px] h-[40px] inline h-full scale-50 md:scale-80'>
                      <g clipPath="url(#clip0_390_1165)">
                      <path d="M10 20H210" stroke="white" strokeWidth="2"/>
                      </g>
                      <path d="M104 20H116M116 20L104.955 10M116 20L104.955 30" stroke="white" strokeWidth="2"/>
                      <defs>
                      <clipPath id="clip0_390_1165">
                      <rect width="94" height="20" fill="white" transform="translate(10 10)"/>
                      </clipPath>
                      </defs>
                  </svg>
                </div>
              </Link> 
              </div>

          </motion.div>
    </>
    );
};

export default ContactUs;
